import React, { useEffect, useState } from "react";
import CircularProgress from "@mui/material/CircularProgress";
import { getUserProductsData } from "../API/firebase";
import { useNavigate } from "react-router-dom";
import { UserProductTypes } from "../models/types";

import Grid from "@mui/material/Unstable_Grid2";

import s1 from "../images/S1/s1-no-logo.png";
import m10 from "../images/M10/m10-no-logo.png";
import x20 from "../images/X20/x20-no-logo.png";
import s10pps from "../images/S10PPS/s10pps-no-logo.png";

import s1Logo from "../images/S1/s1-logo-only.png";
import m10Logo from "../images/M10/m10-logo-only.png";
import x20Logo from "../images/X20/x20-logo-only.png";
import s10ppsLogo from "../images/S10PPS/s10pps-logo-only.png";

import ProductItem from "./product-item";
import { ProductStore } from "../store/store";
import NoProductData from "./no-product-data";

const UserProducts: React.FC = () => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState<boolean>(true);
  const [data, setData] = useState<UserProductTypes[]>([]);

  const [updateProductSerialNo] = ProductStore((state) => [
    state.updateProductSerialNo,
  ]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const products = await getUserProductsData();
        setData(products);
        setLoading(false);
      } catch (error) {
        console.log("Error getting document:", error);
      }
    };

    fetchData();
  }, []);

  const items: Array<any> = data.map((d, i) => {
    let productImage = m10Logo; // Default
    let image = m10;
    if (d.artome_product === "M10") {
      productImage = m10Logo;
      image = m10;
    } else if (d.artome_product === "X20") {
      productImage = x20Logo;
      image = x20;
    } else if (d.artome_product === "S1" || d.artome_product === "S01") {
      productImage = s1Logo;
      image = s1;
    } else if (d.artome_product === "S10PPS") {
      productImage = s10ppsLogo;
      image = s10pps;
    }

    return (
      <Grid key={i} desktop={6} laptop={6} tablet={6} mobile={12}>
        <ProductItem
          productImage={productImage}
          text={d.setup_date}
          image={image}
          buttonPress={(): void => {
            updateProductSerialNo(d.artome_serial_no);
            navigate(`/user-product/${d.artome_serial_no}`);
          }}
        />
      </Grid>
    );
  });

  return (
    <div className="user-products-container">
      <Grid
        padding={5}
        direction="row"
        alignItems="center"
        justifyContent="left"
        container
        spacing={1}
        rowSpacing={1}
        columnSpacing={{ desktop: 2, tablet: 2, mobile: 2 }}
      >
        {loading ? (
          <CircularProgress />
        ) : data.length > 0 ? (
          items
        ) : (
          <NoProductData />
        )}
      </Grid>
    </div>
  );
};

export default UserProducts;
