import { ArrayListType } from "../models/types";
import { Paths } from "../enums/path.enum";

import img1 from "../images/M10/install/1.png";
import img2 from "../images/M10/install/2.png";
import img3 from "../images/M10/install/3.png";
import img4 from "../images/M10/install/4.png";
import img5 from "../images/M10/install/5.png";
import img6 from "../images/M10/install/6.png";
import img7 from "../images/M10/install/7.png";
import img8 from "../images/M10/install/8.png";
import img9 from "../images/M10/install/9.png";
import img10 from "../images/M10/install/10.png";
import img11 from "../images/M10/install/11.png";
import img12 from "../images/M10/install/12.png";
import img13 from "../images/M10/install/13.png";
import img14 from "../images/M10/install/14.png";
import img15 from "../images/M10/install/15.png";
import img16 from "../images/M10/install/16.png";
import img17 from "../images/M10/install/17.png";
import img18 from "../images/M10/install/18.png";
import img19 from "../images/M10/install/19.png";
import img20 from "../images/M10/install/20.png";

const _m10ImageList: Array<ArrayListType> = [
  {
    image: img1,
    text: "Installer toolkit contents: cable harness, LAN cable, thumb screws, speaker cover, speaker cover screws, cable tie and rack mounting accessories. NOTE: The cables might already be in place. No installer toolkit?",
    link: Paths.m10SetupLegacy, //lhttps://artome.atlassian.net/wiki/spaces/WI/pages/202473476
    linkText: "Click here.",
  },
  {
    image: img2,
    text: "Compatible projector models: EB-805F, EB-800F. Lock the wheels before starting the installation.",
  },
  {
    image: img3,
    text: "Remove the back cover.",
  },
  {
    image: img4,
    text: "Push the upper-left corner to open the door. Remove the door by pressing the mechanism at the end of the hinge. Support the door while detaching the hinges.",
  },
  {
    image: img5,
    text: "Remove the speaker panel cover.",
  },
  {
    image: img6,
    text: "Remove the button cover from the projector.",
  },
  {
    image: img7,
    text: "Slide the projector inside the cabinet.",
  },
  {
    image: img8,
    text: "Lower the projector legs to alignment holes.",
  },
  {
    image: img9,
    text: "Fix the projector to cabinet with thumb screws.",
  },
  {
    image: img10,
    text: "Connect cable harness and LAN cable in following order: HDMI 1, LAN cable, projector audio in, RS-232, power. NOTE: Connector placement might vary.",
  },
  {
    image: img11,
    text: "Connect the cable harness to the projector.",
  },
  {
    image: img12,
    text: "Attach cable harness with cable ties.",
  },
  {
    image: img13,
    text: "Connect HDMI2 and LAN cable to the back cover.",
  },
  {
    image: img14,
    text: "Install the back cover.",
  },
  {
    image: img15,
    text: "Re-install the speaker cover. Models with buttons in the speaker cover: also attach the CAT cable.",
  },
  {
    image: img16,
    text: "Install the door. Adjust the hinges if the door is not aligned.",
  },
  {
    image: img17,
    text: "Connect the power cable. The cable is stored inside the device.",
  },
  {
    image: img18,
    text: "Press power and wait 2 minutes until automatic setup is ready. The device will shut down when ready.",
  },
  {
    image: img19,
    text: "Optionally enable automatic power on for HDMI plug-in. Navigate to Menu > Operation. Set Auto Power On: HDMI1 (and if visible, Power On Trigger: Plug-in-Detection).",
  },
  {
    image: img20,
    text: "Installation is ready.",
  },
];

export default _m10ImageList;
